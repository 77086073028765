import React, { FunctionComponent } from 'react';
import Box from './Box';
import { IProps as IBoxProps } from './Box';
import { FelaCSS } from './flowtypes';

interface IProps extends IBoxProps {}

const Text: FunctionComponent<IProps> = ({ style, ...restProps }) => {
  const textStyles: FelaCSS = {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    msHyphens: 'auto',
    ...style,
  };

  return <Box {...restProps} style={textStyles} />;
};

export default Text;
